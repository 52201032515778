.chat-container {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.chat-message {
  padding: 8px 12px;
  border-radius: 15px;
  max-width: 80%;
}

.chat-message.user {
  background-color: #e0f7fa;
  align-self: flex-end;
  text-align: right;
}

.chat-message.ai {
  background-color: #e0e0e0;
  align-self: flex-start;
  text-align: left;
}

.navbar-brand {
  font-size: 1.5rem;
}

.navbar-brand .text-primary {
  color: #007bff !important;
}

.navbar-custom {
  margin-bottom: 0;
}

.hero-custom {
  margin-top: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.load-more-card {
  cursor: pointer;
  transition: all 0.3s ease;
}

.load-more-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.section-button {
  transition: all 0.3s ease;
}

.section-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.news-card {
  transition: all 0.3s ease;
}

.news-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.navbar-custom .nav-link.active {
  font-weight: bold;
  color: #007bff !important;
}
